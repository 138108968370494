import { Component, Mixins } from "vue-property-decorator";
import { AskForHelp } from "@/mixins/AskForHelp";

@Component({ name: "CookiesValidation" })
export default class CookiesValidationController extends Mixins(AskForHelp) {
  sheet = false;

  setCookies(value: any) {
    localStorage.setItem("cookies_checked", JSON.stringify(value));
    this.sheet = false;
  }

  async created() {
    if (!localStorage.getItem("cookies_checked")) {
      this.sheet = true;
    }
  }
}
