<template>
  <v-bottom-sheet v-model="sheet" persistent>
    <v-sheet class="pa-6">
      <v-layout wrap align-center justify-space-between>
        <v-flex xs12 sm10 md10 lg6 xl6>
          <div>
            <h6 class="text-h6 text--secondary font-weight-bold">Leia com atenção, por favor</h6>
            <span class="text--secondary">
              Utilizamos cookies para oferecer melhor experiência, melhorar o desempenho, analisar
              como você interage em nosso site e personalizar conteúdo.
            </span>
          </div>
        </v-flex>
        <v-flex class="mt-4 mt-md-0 mt-sm-4 mt-lg-0">
          <v-layout
            wrap
            class="justify-lg-end align-lg-center justify-md-end flex-column flex-lg-row"
          >
            <v-btn
              class="d-flex flex-lg-grow-0 flex-grow-1"
              outlined
              rounded
              color="grey"
              large
              @click="setCookies(false)"
            >
              Recusar Cookies
            </v-btn>
            <v-btn
              color="primary"
              rounded
              data-cy="cookiesButton"
              elevation="0"
              large
              @click="setCookies(true)"
              class="d-flex flex-lg-grow-0 flex-grow-1 mx-0 mx-lg-2 mx-md-2 my-2 my-lg-0 my-md-0"
            >
              Aceitar Cookies
            </v-btn>
            <v-btn
              v-if="showHelpButtons"
              class="d-flex flex-lg-grow-0 flex-grow-1"
              rounded
              text
              color="grey"
              elevation="0"
              tag="a"
              :href="sendMessage"
              target="_blank"
            >
              Precisa de ajuda?
            </v-btn>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-sheet>
  </v-bottom-sheet>
</template>

<script>
import CookiesValidationController from "./CookiesValidationController";
export default CookiesValidationController;
</script>
